import './scripts/common';
import './scripts/common-nav';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


import * as datepicker from "@elements/datepicker";
datepicker.init();

import * as datepickerRange from '@elements/datepicker-range';
datepickerRange.init();

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init({submitOnReset:true, addUrlParams: true});

import * as scrollToTop from './scripts/scroll-to-top';
scrollToTop.init();

import * as historyBack from './scripts/history-back';
historyBack.init();

import * as googleMap from './scripts/google-map';
googleMap.init();

import {createApp} from 'vue';
import BookingQuickfinder from './scripts/components/VueBookingQuickfinder.vue';
const vueApp = createApp({
    components: {
        BookingQuickfinder
    }
})
vueApp.mount('#vue-booking-quickfinder');

import * as renderTemplate from './scripts/render-template';
renderTemplate.init();

import * as loadInViewport from './scripts/load-in-viewport';
loadInViewport.init();

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as smoothScroll from "@elements/smooth-scroll";
smoothScroll.init({
    scrollOffset: (matchMedia('(min-width: 768px)').matches ? 180 : 50),
});